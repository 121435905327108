






































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  private data: any = [
    {
      字段名称: "掌型",
      字段英文名称: "Palm Type",
      字段释义: "手掌的形状和特征，每个人的掌型都可能有所不同。",
      值域范围说明:
        "原始型、四方型、竹节型、圆锥型、汤匙型、鼓槌型、柔弱型混合型、胖手、瘦手、硬手、软手",
    },
    {
      字段名称: "掌色",
      字段英文名称: "Palm Color",
      字段释义:
        "手掌的皮肤颜色，它可以因个体差异、种族、遗传、健康状态以及环境因素等多种因素而有所不同。",
      值域范围说明: "淡红色、白色、红色、肝掌、紫红色、黄色、青色、黑色",
    },
    {
      字段名称: "1线",
      字段英文名称: "Life Line",
      字段释义:
        "起于手掌尺侧，从小掌指褶纹下1.5-2厘米处，以弧形、抛物状延伸到食指与中指指缝之间下方，此线以深长、明晰、颜色红润，杂纹少为正常。此线主要代表呼吸及消化系统功能的强弱。又称感情线、远端横曲线、天线。",
      值域范围说明:
        "正常、延长至指缝、延长至巽宫、干扰线、十字纹、井字纹、米字纹、五角星纹、三角形纹、方格纹、岛型纹、环形纹、畸断、羽毛状纹",
    },
    {
      字段名称: "2线",
      字段英文名称: "Intellectual Line",
      字段释义:
        "起于手掌桡侧，从食指掌指褶纹与拇指掌指褶纹内侧连线的1/2处，向小鱼际抛行，止于无名指中线。粗而长，明晰不断，颜色红润，略微下垂，近掌心末端可有分枝。此线多提示神经、精神方面及心血管系统疾患。亦称近端横曲线、小鱼际抛物线、脑线、智慧线、人线。",
      值域范围说明:
        "正常、起点抬高、起点偏低、过长、下垂、平直、大岛纹、分叉、小岛纹、十字纹、井字纹、米字纹、五角星纹、三角形纹、方格纹、环形纹、畸断、羽毛状纹",
    },
    {
      字段名称: "3线",
      字段英文名称: "Heart Line",
      字段释义:
        "起于手掌桡侧，从食指根线与拇指指根线中点，包绕整个拇指球，弧度不超过中指中线以微粗、明晰不断、颜色红润为常，此线可提示人的体质、精力、能力、健康状况及疾病情况。亦称大鱼际曲线、大鱼际抛物线、生命线、地线。",
      值域范围说明:
        "正常、弧度过大、弧度过小、起点偏高、起点偏低、岛纹、十字纹、井字纹、米字纹、五角星纹、三角形纹、方格纹、岛型纹、环形纹、畸断、羽毛状纹、过短、干扰线、护线",
    },
    {
      字段名称: "4线",
      字段英文名称: "Health Line",
      字段释义:
        "起于大小鱼际交接处(以不接触3线为原则)，斜行向小指方向(以不接触1线为原则)。-长短不一，一般手上无此线为好。此线大多见于劳心或身体弱的人。亦称健康线。",
      值域范围说明: "无、深长、浅短、切入3线、切入1线",
    },
    {
      字段名称: "5线",
      字段英文名称: "Destiny Line",
      字段释义:
        "从手掌的下方，通过掌心，直达中指下方的线。此线不能太粗，以细浅为要，笔直而上，明晰不断，颜色红润。此线常提示心血管、呼吸系统、消化系统的功能如慢性心肺疾病亦称玉柱线。",
      值域范围说明: "无、有",
    },
    {
      字段名称: "6线",
      字段英文名称: "Disturbance Line",
      字段释义:
        "横切各主线或辅线的不正常纹线，位置不固定此线细、短、浅时，病理意义不大，深长时有临床价值。提示近期常有饮食不规律、工作压力较大的情况。亦称干扰线、障碍线。",
      值域范围说明: "无、深长、浅短、切入1线、切入2线、切入3线",
    },
    {
      字段名称: "7线",
      字段英文名称: "Success Line",
      字段释义:
        "是一条位于无名指下的竖线。是5线的副线，比5线短。此线多与血压的高低有关。亦称太阳线。",
      值域范围说明: "无、有、切入1线、未切入1线",
    },
    {
      字段名称: "8线",
      字段英文名称: "Indulgence Line",
      字段释义:
        "位于小鱼际，腕横纹上1-2厘米处，是一条短横线。一般人少见。此线提示生活不规律长期熬夜，劳累过度，长期服用安眠药、麻醉品。亦称放纵线、远航线。",
      值域范围说明: "无、有",
    },
    {
      字段名称: "9线",
      字段英文名称: "Venus Line",
      字段释义:
        "起于食指与中指的指缝间，以弧形延伸到无名指与小指指缝间多提示过敏体质。亦称金星线。",
      值域范围说明: "无、有",
    },
    {
      字段名称: "10线",
      字段英文名称: "Saturn Line",
      字段释义:
        "在中指基底部，为一弧形半月圆。此线提示患者性格孤僻，常见于肝气不疏者。亦称土星线。",
      值域范围说明: "无、有",
    },
    {
      字段名称: "11线",
      字段英文名称: "Sexual line",
      字段释义:
        "位于小指掌指褶纹与1线中间其长度约近小指根112处。多数人拥有2条。其线以平直，明晰不断，颜色浅红者佳。异常多见于泌尿生殖疾病。亦称性线。",
      值域范围说明: "无、有、短、只有1条、2条、3条、过长、米字纹、干扰线",
    },
    {
      字段名称: "12线",
      字段英文名称: "Liver disease line",
      字段释义:
        "起于小指掌指褶纹与1线中间，向无名指下延伸的一条横线。日本人认为此线与“痛风”症有关:临床上此线出现于慢性肝炎患者的机率大，也见于长期嗜酒者。亦称肝病线、酒线。",
      值域范围说明: "无，有",
    },
    {
      字段名称: "13线",
      字段英文名称: "Sydney Line",
      字段释义:
        "实际上是2线变异一直延伸到掌端而成。亦称悉尼线，因为1970年前后，有研究者在澳大利亚的悉尼发现的一种特异的掌屈纹，据他们报道，此线见于先天风疹、白血病和先天愚型患者，也常见于发育迟缓、行为异常的孩子。此线与癌症有密切关系。",
      值域范围说明: "无、有、岛纹、起点不与3线重合",
    },
    {
      字段名称: "14线",
      字段英文名称: "Through line",
      字段释义:
        "当1线消失，2线与3线单独在手上出现。提示多有遗传性，其人的体质、智力、寿命、疾病的发展状况均与父母情况接近。此种手纹的人遗传倾向极强。亦称通贯线、猿猴纹。",
      值域范围说明: "无、有",
    },
    {
      字段名称: "天庭十字纹",
      字段英文名称: "Forehead Cross Line",
      字段释义: "一种出现在额头部位的纹路，形状类似于一个十字。",
      值域范围说明: "无、有",
    },
    {
      字段名称: "艮震线",
      字段英文名称: "Gen-Chen Line",
      字段释义:
        "手掌上的一条辅助线，也被称为生命线旁线或健康线。这条线起始于生命线的下方，沿着手掌的边缘向手腕方向延伸。",
      值域范围说明: "无、有",
    },
    {
      字段名称: "咽区",
      字段英文名称: "Laryngeal Area",
      字段释义:
        "位于手掌上的一个特定区域，通常与个体的咽喉部位和呼吸系统相关联。",
      值域范围说明:
        "岛纹、十字纹、井字纹、米字纹、五角星纹、三角形纹、方格纹、环形纹",
    },
    {
      字段名称: "三星高照",
      字段英文名称: "Three Lucky Stars on the Palm",
      字段释义:
        "在离位、2线末端和3线末端有“米”字纹;于手掌尺侧，1线下方;只有三个位置同时出现“米”字纹才是“三星高照”。",
      值域范围说明: "无、有",
    },
    {
      字段名称: "乳腺纹",
      字段英文名称: "Heart Line",
      字段释义:
        "在无名指下，1线与2线之间形成如树叶形状的纹理，此线为病理线，无者为正常",
      值域范围说明:
        "正常、弧度过大、弧度过小、起点偏高、起点偏低、岛纹、十字纹、井字纹、米字纹、五角星纹、三角形纹、方格纹、岛型纹、环形纹、畸断、羽毛状纹、过短、干扰线、护线",
    },
    {
      字段名称: "血脂邱",
      字段英文名称: "Palm Lipid Mound",
      字段释义: "手掌上某个与血脂水平可能相关的区域或纹路。",
      值域范围说明: "无、有",
    },
    {
      字段名称: "孔子眼",
      字段英文名称: "Old or tender",
      字段释义: "拇指的第一褶纹呈眼睛形状;此纹为生理纹。",
      值域范围说明: "无、有",
    },
    {
      字段名称: "暴怒指",
      字段英文名称: "Palm Anger Finger",
      字段释义:
        "大拇指指端肥大且圆;拇指短;指甲宽短扁平;为病理指型，无者为正常。",
      值域范围说明: "无、有",
    },
    {
      字段名称: "巽宫",
      字段英文名称: "Xùn Palace",
      字段释义:
        "位于食指根的掌丘处，有人称食指丘，国外称木星丘。属木，对应胆、股部、胸部、气管、左肩背。",
      值域范围说明:
        "正常、淡红、淡白、红、紫、青、黑、十字纹、井字纹、米字纹、五角星纹、三角形纹、方格纹、岛型纹、环形纹",
    },
    {
      字段名称: "离宫",
      字段英文名称: "Li Palace",
      字段释义:
        "位于中指根和1/2无名指根掌丘处，有人称中指丘，包含国外的土星丘和112太阳丘部分。属火，对应心脏、眼、三焦、小肠。",
      值域范围说明:
        "正常、淡红、淡白、红、紫、青、黑、十字纹、井字纹、米字纹、五角星纹、三角形纹、方格纹、岛型纹、环形纹",
    },
    {
      字段名称: "坤宫",
      字段英文名称: "Kun Palace",
      字段释义:
        "位于1/2无名指根掌区和全部小指掌区处外国称太阳丘和水星丘。属土，对应脾胃、腹部、肌肉、右肩。",
      值域范围说明:
        "正常、淡红、淡白、红、紫、青、黑、十字纹、井字纹、米字纹、五角星纹、三角形纹、方格纹、岛型纹、环形纹",
    },
    {
      字段名称: "震宫",
      字段英文名称: "Zhen Palace",
      字段释义:
        "位于手拇指内侧，俗称虎口处以内，大鱼际抛物线以外的三角形的掌区。国外称为第一火星丘，属肤纹学鱼际区的远侧端。属木，对应肝、足、筋爪、左肩臂。",
      值域范围说明:
        "正常、淡红、淡白、红、紫、青、黑、十字纹、井字纹、米字纹、五角星纹、三角形纹、方格纹、岛型纹、环形纹",
    },
    {
      字段名称: "明堂",
      字段英文名称: "Ming Tang",
      字段释义:
        "位于手掌的正中央，俗称掌心。国外称火星平原。属火，对应胃肠、心血管系统，内分泌并反映人的心理状态。",
      值域范围说明:
        "正常、淡红、淡白、红、紫、青、黑、十字纹、井字纹、米字纹、五角星纹、三角形纹、方格纹、岛型纹、环形纹",
    },
    {
      字段名称: "兑宫",
      字段英文名称: "Tui Palace",
      字段释义:
        "位于坤，乾两宫之间，在小指根下横曲线以下手掌尺侧部掌区，有人称为丘间区，国外称第二火星丘。属金，对应肺、支气管、口腔、右肩臂、右胁、肛门。",
      值域范围说明:
        "正常、淡红、淡白、红、紫、青、黑、十字纹、井字纹、米字纹、五角星纹、三角形纹、方格纹、岛型纹、环形纹",
    },
    {
      字段名称: "艮宫",
      字段英文名称: "Gen Palace",
      字段释义:
        "位于拇指根的掌丘部，肤纹学称为鱼际区，国外称为金星丘。属土，对应胃、上肢、下肢腰肩、关节、生殖器。",
      值域范围说明:
        "正常、淡红、淡白、红、紫、青、黑、十字纹、井字纹、米字纹、五角星纹、三角形纹、方格纹、岛型纹、环形纹",
    },
    {
      字段名称: "坎宫",
      字段英文名称: "Kan Palace",
      字段释义:
        "位于掌根部正中间，皮肤纹学研究的T三叉部位，有人称为地宫(丘)。属水，对应肾脏、膀胱、腰、背脊、血液、生殖系统。",
      值域范围说明:
        "正常、淡红、淡白、红、紫、青、黑、十字纹、井字纹、米字纹、五角星纹、三角形纹、方格纹、岛型纹、环形纹",
    },
    {
      字段名称: "乾宫",
      字段英文名称: "Qian Palace",
      字段释义:
        "位于手腕横纹尺侧外掌区的突出部.肤纹学称小鱼际区，国外称为月丘处。属金，对应大肠、肺、头、胸部、骨。",
      值域范围说明:
        "正常、淡红、淡白、红、紫、青、黑、十字纹、井字纹、米字纹、五角星纹、三角形纹、方格纹、岛型纹、环形纹",
    },
  ];
}
